//
//
//
//
//
//
//
//

export default {
    components: {},
    data() {
        return {
            list: [],
        };
    },
    created() {
        this.getInit();
    },
    computed: {
        pages() {
            return this.$store.state.page.pageInfo;
        },
        configInfo() {
            return this.$store.state.base.configInfo;
        },
    },
    methods: {
        async getInit() {
            console.log(this.pages);
            this.list = this.pages.List;
            document.title = this.list.find((item) => item.name == "HvTitle").title || this.configInfo.storeName;
        },
    },
};
